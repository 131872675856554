import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home mt-6 mt-md-16"},[_c('transition-group',{attrs:{"appear":"","name":"home__fade"}},[_c(VRow,{key:"0",staticClass:"home__banner mb-8 mx-auto",attrs:{"align":"center"}},[_c(VCol,{staticClass:"home__banner--title",attrs:{"align-self":"center"}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("Spencer")]),_vm._v(" Krips Music ")]),_c(VCol,{staticClass:"pa-0",attrs:{"align-self":"center"}},[_c(VImg,{staticClass:"home__banner--image",attrs:{"src":_vm.Home.bannerImage},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c(VRow,{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}])})],1)],1),_c(VRow,{key:"1",staticClass:"home__feature pa-0",attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c(VImg,{staticClass:"align-end home__feature--image",attrs:{"src":_vm.Home.featureImage,"max-width":"1240"}},[_c(VRow,{staticClass:"my-0 my-sm-6 my-md-10 my-lg-16",attrs:{"no-gutters":"","justify":"center","align":"end"}},[_c(VDialog,{attrs:{"tile":"","width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"home__feature--button",attrs:{"tile":"","color":"#8a4d58"}},'v-btn',attrs,false),on),[_vm._v("Listen Now")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{attrs:{"tile":"","color":"#015869"}},[_c(VCardActions,[_c(VCardTitle,{staticClass:"home__modal--title text-h5"},[_vm._v(" Listen to behold, the sonic universe ")]),_c(VSpacer),_c(VBtn,{staticClass:"footer__dialog--close",attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c(VBtn,{staticClass:"home__modal--button my-2",attrs:{"tile":"","large":"","color":"#8a4d58","elevation":"0","to":{
                    name: 'Product',
                    params: { id: 7584649216248 },
                  },"target":"_blank","rel":"noreferrer"}},[_vm._v("Buy Now")]),_c(VBtn,{staticClass:"home__modal--button my-2",attrs:{"tile":"","large":"","color":"#8a4d58","elevation":"0","href":"https://open.spotify.com/album/7sS7mbQzasGlm9QSqpHxOt","target":"_blank","rel":"noreferrer"}},[_c(VIcon,{staticClass:"mr-2",attrs:{"size":"30","color":"white"}},[_vm._v(" mdi-spotify ")]),_vm._v(" Listen on Spotify")],1),_c(VBtn,{staticClass:"home__modal--button my-2",attrs:{"tile":"","large":"","color":"#8a4d58","elevation":"0","href":"https://music.apple.com/us/album/behold-the-sonic-universe/1586246399","target":"_blank","rel":"noreferrer"}},[_c(VImg,{staticClass:"mr-4",attrs:{"src":require("../assets/icons/apple-music.png"),"max-width":"24"}}),_vm._v(" Listen on Apple Music")],1),_c(VBtn,{staticClass:"home__modal--button my-2",attrs:{"tile":"","large":"","color":"#8a4d58","elevation":"0","href":"https://music.youtube.com/playlist?list=OLAK5uy_nbOlukt1KkNLEkGYfqNTFqyS1gQYITwiY","target":"_blank","rel":"noreferrer"}},[_c(VIcon,{staticClass:"mr-2",attrs:{"size":"30","color":"white"}},[_vm._v(" mdi-youtube ")]),_vm._v(" Listen on Youtube Music")],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }