






































































































































































import Vue from "vue";
import { Home } from "@/constants";

export default Vue.extend({
  name: "Home",

  components: {},
  data: () => ({
    dialog: false,
    Home,
  }),
});
